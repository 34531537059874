import React from "react"
import styled, { css } from "styled-components"
import Helmet from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import Parser from "html-react-parser"
import { useForm, Controller } from "react-hook-form"

import {
  Button,
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Tooltip,
  TextField,
} from "@material-ui/core"

import useShopSettings from "components/shopifyProduct/useShopSettings"

import Edges from "../components/edges"
import useShopify from "shopify"
import LineItem from "components/cart/LineItem"

import formatCurrencyString from "../utils/formatCurrencyString"

export const ShoppingBagPage = () => {
  const { checkout, addOrderNote } = useShopify()

  const wpProducts = useAllProducts()

  const { control, watch, register } = useForm()

  const agreement = watch("agreement")
  const orderNote = watch("order_note")
  const isGift = watch("is_gift")

  const { agreementText } = useShopSettings()

  const handleOrderNote = () => {
    addOrderNote(orderNote)
  }

  return (
    <Container>
      <Helmet>
        <title>Shopping Bag</title>
      </Helmet>

      <Edges size="lg">
        <Typography variant="h3" gutterBottom>
          Your Shopping Bag
        </Typography>

        {checkout?.lineItems?.length === 0 && (
          <div>
            <Typography
              gutterBottom
              css={css`
                margin-bottom: 30px;
              `}
            >
              Your shopping bag is currently empty!
            </Typography>
            <Button
              to="/collections/boutique"
              component={Link}
              variant="contained"
            >
              Continue shopping
            </Button>
          </div>
        )}

        {checkout?.lineItems && !!checkout.lineItems.length && (
          <div
            css={css`
              border: 1px solid #dddddf;
            `}
          >
            <ItemsHeader>
              <Typography
                css={css`
                  width: 90px;
                  margin-right: 16px;
                  flex-shrink: 0;
                `}
              >
                Image
              </Typography>
              <Typography
                css={css`
                  width: 100%;
                  margin-right: 16px;
                `}
              >
                Product
              </Typography>
              <Typography
                css={css`
                  flex-shrink: 0;
                  width: 120px;
                  margin-right: 16px;
                `}
              >
                Price
              </Typography>
              <Typography
                css={css`
                  flex-shrink: 0;
                  width: 130px;
                  margin-right: calc(
                    16px + 48px
                  ); // 48px is width of delete button
                `}
              >
                Amount
              </Typography>
            </ItemsHeader>
            <Items>
              {checkout.lineItems.map(lineItem => {
                return (
                  <LineItem
                    key={lineItem.id}
                    {...lineItem}
                    wpProducts={wpProducts}
                  />
                )
              })}
            </Items>

            <div
              css={css`
                margin: 10px 16px;
              `}
            >
              <FormGroup
                css={css`
                  margin-bottom: 10px;
                `}
              >
                <Controller
                  name={`is_gift`}
                  control={control}
                  defaultValue=""
                  required
                  render={props => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={e => props.onChange(!!e.target.checked)}
                          checked={!!props.value}
                          required
                        />
                      }
                      name="is_gift"
                      label={`This is a gift`}
                    />
                  )}
                />
              </FormGroup>

              {isGift && (
                <TextField
                  name="order_note"
                  inputRef={register}
                  placeholder="Enter a special message to be handwritten for the recipient here"
                  fullWidth
                  multiline
                  variant="outlined"
                  css={css`
                    && {
                      .MuiInputBase-root {
                        max-width: 600px;
                      }
                      .MuiInputBase-input {
                        min-height: 100px;
                      }
                    }
                  `}
                  onBlur={handleOrderNote}
                />
              )}
            </div>

            <Footer>
              <Subtotal>
                {checkout?.lineItems.length >= 1 &&
                  checkout?.subtotalPriceV2?.amount && (
                    <>
                      <Typography>Subtotal</Typography>
                      <div className="footer__subTotal">
                        <Typography className="footer__subTotalPrice">
                          {checkout?.currencyCode}{" "}
                          {formatCurrencyString({
                            amount: checkout?.subtotalPriceV2?.amount,
                            currencyCode:
                              checkout?.subtotalPriceV2?.currencyCode,
                          })}
                        </Typography>
                      </div>
                    </>
                  )}
              </Subtotal>

              <div
                css={css`
                  display: flex;
                  flex-wrap: wrap;
                  flex-direction: column;
                  align-items: flex-end;
                `}
              >
                {checkout?.webUrl && checkout?.lineItems.length >= 1 && (
                  <>
                    <FormGroup>
                      <Controller
                        name={`agreement`}
                        control={control}
                        defaultValue=""
                        required
                        render={props => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={e =>
                                  props.onChange(!!e.target.checked)
                                }
                                checked={!!props.value}
                                required
                              />
                            }
                            name="agreement"
                            label={agreementText && Parser(agreementText)}
                          />
                        )}
                      />
                    </FormGroup>

                    <Tooltip
                      title={`Please read the agreement before continuing`}
                      disableHoverListener={agreement}
                      disableFocusListener={agreement}
                      disableTouchListener={agreement}
                    >
                      <div>
                        <Button
                          disabled={!agreement}
                          variant="contained"
                          href={checkout.webUrl}
                          style={{
                            display: "inline-block",
                            marginLeft: "auto",
                          }}
                        >
                          Checkout
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </Footer>
          </div>
        )}
      </Edges>
    </Container>
  )
}

const Container = styled.div`
  padding: 60px 0;
  background: white;
  height: 100%;
  min-height: 68vh;
`

const Footer = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Items = styled.div`
  padding: 16px 0;
`
const ItemsHeader = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #dddddf;
  }
`
const Subtotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .footer {
    &__currencyCode {
      font-size: 14px;
      margin-right: 5px;
    }
    &__subTotal {
      display: flex;
      align-items: center;
    }
    &__subTotalPrice {
      font-weight: 500;
    }
  }
`

const useAllProducts = () => {
  const {
    allWpProduct: { nodes },
  } = useStaticQuery(graphql`
    query AllProducts {
      allWpProduct {
        nodes {
          id
          shopifyProductId
          product {
            shopifyId
          }
          title
          uri
          acfProduct {
            linkedFrProduct {
              __typename
              ... on WpProduct {
                title
                uri
                id
                product {
                  shopifyId
                }
                shopifyProductId
              }
            }
          }
        }
      }
    }
  `)

  return nodes
}

export default ShoppingBagPage
