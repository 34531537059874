import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import Parser from "html-react-parser"

import {
  Typography,
  IconButton,
  CircularProgress,
  OutlinedInput,
} from "@material-ui/core"

import RemoveIcon from "@material-ui/icons/Remove"
import AddIcon from "@material-ui/icons/Add"
import DeleteIcon from "@material-ui/icons/Delete"
import config from "shopify/config"
import formatCurrencyString from "utils/formatCurrencyString"
import useShopify from "shopify"
import useShopSettings from "../shopifyProduct/useShopSettings"

const LineItem = props => {
  const { id, title, quantity, variant, wpProducts, ...rest } = props

  const {
    removeLineItem,
    updateLineItem,
    getVariantAvailability,
  } = useShopify()

  const { cartItemPreorderText, cartItemFrontRowText } = useShopSettings()

  const [loading, setLoading] = useState(false)

  const wpProduct =
    wpProducts &&
    wpProducts.find(item => item?.product?.shopifyId === variant?.product?.id)

  const frProduct =
    wpProducts &&
    wpProducts.find(item => {
      const foundLinkedFrProduct =
        item?.acfProduct?.linkedFrProduct &&
        item?.acfProduct?.linkedFrProduct.find(
          linkedFrProduct =>
            linkedFrProduct.product?.shopifyId === variant?.product?.id
        )

      if (foundLinkedFrProduct) {
        return item
      } else {
        return null
      }
    })

  const variantAvailability = getVariantAvailability(variant?.id)

  const isPreorderProduct =
    variantAvailability?.availableForSale &&
    variantAvailability?.inventoryQuantity <= 0

  return (
    <Container {...rest}>
      {variant?.image?.src && (
        <ImageContainer>
          <img src={variant.image.src} alt={variant?.image?.altText} />
        </ImageContainer>
      )}

      <Info>
        <Link
          to={frProduct?.uri || wpProduct?.uri}
          css={css`
            color: inherit;
            display: inline-block;
          `}
        >
          <Title variant="h5">{frProduct?.title || title}</Title>
        </Link>
        {variant?.title && variant?.title !== "Default Title" && (
          <SubTitle variant="subtitle2">{variant.title}</SubTitle>
        )}

        {!!frProduct && cartItemFrontRowText && (
          <div>{Parser(cartItemFrontRowText)}</div>
        )}

        {isPreorderProduct && cartItemPreorderText && (
          <div>{Parser(cartItemPreorderText)}</div>
        )}
      </Info>

      <Price>
        <Typography
          css={css`
            ${!!variant?.compareAtPrice &&
              css`
                margin-right: 10px;
              `}
          `}
        >
          {config?.defaultCurrencyCode}{" "}
          {variant?.price &&
            formatCurrencyString({
              amount: variant?.price?.amount,
              currencyCode: config?.defaultCurrencyCode,
            })}
        </Typography>

        {!!variant?.compareAtPrice && (
          <Typography
            css={css`
              font-style: italic;
              text-decoration: line-through;
            `}
          >
            {config?.defaultCurrencyCode}{" "}
            {variant?.compareAtPrice?.amount &&
              formatCurrencyString({
                amount: variant?.compareAtPrice?.amount,
                currencyCode: config?.defaultCurrencyCode,
              })}
          </Typography>
        )}
      </Price>

      <AmountDeleteContainer>
        <Amount>
          <OutlinedInput
            css={css`
              padding-left: 0 !important;
              padding-right: 0 !important;
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              width: 130px;
              .MuiInputBase-input {
                -moz-appearance: textfield;
                text-align: center;
              }
              .MuiOutlinedInput-adornedStart {
                padding-left: 0 !important;
              }
              .MuiOutlinedInput-adornedEnd {
                padding-right: 0;
              }
            `}
            variant="outlined"
            value={quantity}
            type="Number"
            onChange={e => {
              if (parseInt(e.target.value) < 1) {
                updateLineItem({
                  lineItemId: id,
                  quantity: 1,
                })
              } else {
                updateLineItem({
                  lineItemId: id,
                  quantity: e.target.value,
                })
              }
            }}
            inputProps={{ min: 1 }}
            startAdornment={
              <IconButton
                onClick={() => {
                  if (quantity > 1) {
                    updateLineItem({
                      lineItemId: id,
                      quantity: quantity - 1,
                    })
                  } else {
                    updateLineItem({
                      lineItemId: id,
                      quantity: 1,
                    })
                  }
                }}
                disableRipple
                css={css`
                  &:hover {
                    color: black;
                  }
                `}
              >
                <RemoveIcon />
              </IconButton>
            }
            endAdornment={
              <IconButton
                onClick={() => {
                  updateLineItem({ lineItemId: id, quantity: quantity + 1 })
                }}
                disableRipple
                css={css`
                  &:hover {
                    color: black;
                  }
                `}
              >
                <AddIcon />
              </IconButton>
            }
          />
        </Amount>

        <DeleteButton
          loading={loading}
          onClick={() => {
            setLoading(true)
            removeLineItem({
              notification: `Removed ${title} from shopping bag`,
              lineItemId: id,
              onCompleted: () => setLoading(false),
              onError: () => setLoading(false),
            })
          }}
        />
      </AmountDeleteContainer>
    </Container>
  )
}

export default LineItem

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 10px 16px;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    align-items: center;
  }
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  width: 90px;
  margin-right: 16px;
  margin-bottom: 10px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

const Title = styled(Typography)`
  && {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }

    @media (max-width: 960px) {
      font-size: 20px;
    }
  }
`

const SubTitle = styled(Typography)`
  && {
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
`

const Info = styled.div`
  width: 100%;
  margin-right: 16px;
`

const Price = styled.div`
  flex-shrink: 0;
  width: 120px;
  margin-right: 16px;
`

const AmountDeleteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`

const Amount = styled.div`
  width: 130px;
  margin-right: 16px;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`

const DeleteButton = ({ loading, ...rest }) => {
  return (
    <IconButton {...rest}>
      <DeleteIcon
        css={css`
          transition: opacity 0.3s ease-in-out;
          ${loading
            ? css`
                opacity: 0;
              `
            : css`
                opacity: 1;
              `}
        `}
      />

      <CircularProgress
        size={20}
        css={css`
          position: absolute;
          top: 14px;
          left: 14px;
          transition: opacity 0.2s ease-in-out;
          ${loading
            ? css`
                opacity: 1;
              `
            : css`
                opacity: 0;
              `}
        `}
      />
    </IconButton>
  )
}
